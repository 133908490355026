import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
export default class extends Controller {
  static targets = ["plane"]
  static values = {
    data: String
  }

  connect() {
    const datasets = JSON.parse(this.dataValue)
    const last_two = datasets.slice(-2)
    const color = (last_two.size == 2 && last_two[1] > last_two[0]) ? '#198754' : '#dc3545'
    const backgroundColor = (last_two.size == 2 && last_two[1] > last_two[0]) ? 'rgba(25, 135, 84, 0.4)' : 'rgba(220, 53, 69, 0.4)'
    const data = {
      labels: datasets,
      datasets: [{
        label: 'My First Dataset',
        data: datasets,
        borderColor: color,
        backgroundColor: backgroundColor,
        fill: true,
        tension: 0.1
      }],
    };
    const config = {
      type: 'line',
      data: data,
      options: {
        elements: {
          point: {
            radius: 0
          }
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false
          }
        },
      },
    };
    const myChart = new Chart(this.planeTarget, config);
  }
}
