import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "parent", "eye"]

  toggle(){
    if (!this.eyeTarget.classList.contains('slash')){
      this.eyeTarget.classList.add('slash')
      this.eyeTarget.title = 'Спрятать пароль'
      this.inputTarget.type = 'text'
    } else {
      this.eyeTarget.title = 'Показать пароль'
      this.eyeTarget.classList.remove('slash')
      this.inputTarget.type = 'password'
    }
  }
}
