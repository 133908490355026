import { Controller } from "@hotwired/stimulus"
import toastr from 'toastr';

export default class extends Controller {
  static targets = ["alert"]
  connect() {
    toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toast-top-left",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }

    this.alertTargets.forEach( (alert) => {
      const message = alert.dataset.alert
      const type = alert.dataset.type
      if (type == 'alert')
        toastr.error(message);
      else
        toastr.info(message);
    })
  }
}
