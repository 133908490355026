import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["currency_select", 'amount', 'hint', 'amount_currency']

  connect() {
    const ajax_url = this.currency_selectTarget.dataset.url;
    const initial_value = this.currency_selectTarget.dataset.initial
    new TomSelect(this.currency_selectTarget, {
      plugins: ['no_backspace_delete'],
      searchField: ['isoCode', 'text'],
      render: {
        option: function (item, escape) {
          return `<div class="py-2 d-flex">
                <div class="icon me-3">
                  <img class="img-fluid" style="width: 32px;" src="${item.src}" />
                </div>
                <div>
                  <div class="mb-1">
                    <span class="h4">
                      ${escape(item.isoCode)}
                    </span>
                    <span class="text-muted">${escape(item.text)}</span>
                  </div>
                </div>
              </div>`;
        },
        item: function (item, escape) {
          return `<div class="py-2 d-flex">
                <div class="icon me-3">
                  <img class="img-fluid" style="width: 32px;" src="${item.src}" />
                </div>
                <div>
                  <div class="mb-1">
                    <span class="h4">
                      ${escape(item.isoCode)}
                    </span>
                    <span class="text-muted">${escape(item.text)}</span>
                  </div>
                </div>
              </div>`;
        }
      },
    });

    this.update_amount_hint()
  }

  update_amount_hint() {
    const hint = this.hintTarget;
    const amount = parseFloat(this.amountTarget.value)
    const option = this.currency_selectTarget.options[this.currency_selectTarget.selectedIndex]
    const from_rates = JSON.parse(option.dataset.from_rates);
    const to_rates = JSON.parse(option.dataset.to_rates);
    const selected_amount_currency = this.amount_currencyTarget.options[this.amount_currencyTarget.selectedIndex].value
    const to_rate = parseFloat(to_rates[selected_amount_currency])
    const from_rate = parseFloat(from_rates[selected_amount_currency])

    if (option && amount && from_rate && to_rate)
      hint.innerHTML = `~${amount * to_rate} ${option.dataset.isoCode}`
    else
      hint.innerHTML = '';
  }
}
